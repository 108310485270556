<template>
  <store-sales-returns />
</template>

<script>
import StoreSalesReturns from '@/components/pages/sales/StoreSalesReturns'

export default {
  components: {
    StoreSalesReturns
  }
}
</script>
